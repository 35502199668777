<template>
  <div>
    <div class="filter">
      <a-row>
        <a-col :xxl="4" :xl="5" :lg="6" :sm="12" :xs="24" class="flex">
          <label>考试日期：</label>
          <a-date-picker class="flex1" :allowClear="true" v-model="filter.cdExamDay" placeholder="考试日期" format="YYYY-MM-DD" />
        </a-col>
        <a-col :xl="7" :lg="6" :sm="12" class="flex ">
          <a-button type="primary" class="dis" @click="filter.pageNum=1;getTrainList()">查询</a-button>
          <a-button  @click="exportStu" icon="cloud-download">打印练车名单</a-button>
        </a-col>
      </a-row>
    </div>
    <a-table :columns="roleColums" :data-source="trainList" :pagination="false">
      <span slot="studentName" slot-scope="text, record, index">{{index+1}}</span>
    </a-table>
  </div>
</template>
<script>
const roleColums = [
  {
    title: '序号',
    scopedSlots: { customRender: 'studentName' },
    key: 'studentName',
  },
  {
    title: '车辆编号',
    dataIndex: 'carHotCode',
    key: 'carHotCode',
  },
  {
    title: '小票数',
    dataIndex: 'billNum',
    key: 'billNum',
  },
  {
    title: '训练数量',
    dataIndex: 'totalPracticeNum',
    key: 'totalPracticeNum',
  },
]
export default {
  components:{
  },
  props:{
    
  },
  data() {
    return {
      showTemplate:false,
      filter:{
        examSpotId:this.$store.state.curSpotId,
        cdExamDay:null
      },
      trainList:[],
      roleColums,
      
    }
  },
  mounted(){
    this.filter.cdExamDay = this.$moment(new Date()).format("YYYY-MM-DD"),
    this.getTrainList()
  },
  methods: {
    exportStu(){
      this.$downExe( this.$url.导出练车名单, this.filter, '练车名单' )
    },
    changPage(page){
      this.filter.pageNum = page;
      this.getTrainList()
    },
    getTrainList(){
      if(this.filter.cdExamDay)this.filter.cdExamDay=this.$moment(this.filter.cdExamDay).format('YYYY-MM-DD')
      this.$post(this.$url.练车名单,this.filter).then(data => {
        this.trainList = data.data.list
      }).catch(() => {
          
      });
    },
  },
}
</script>
<style scoped>
.import-eg{
  padding-top:15px;
  border-top:1px solid rgba(0,0,0,0.05);
  margin-top:10px;
}
.flex{
  margin:5px 0;
  padding:0 5px;
}
.act-con{
  padding:5px 0 0;
}
.act-con button{
  margin:5px 3px;
}
.filter{
  height:auto;
  margin-bottom:15px;
  line-height:30px;
}
.type-name{
  height:30px;
  width:70px;
  top:0;
  margin-right:0;
}
.clear-btn{
  margin-right:10px;
}
</style>
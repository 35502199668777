<template>
  <div>
    <TrainList />
  </div>
</template>
<script>
import TrainList from './components/TrainList'
export default {
  components:{
    TrainList
  }
}
</script>